@import "../styles";

.saved-items__item-group,
.saved-items__loading,
.saved-items__empty {
  display: inline-block;
  border-top: 1px dotted $regal-blue;
  font-family: $sans-serif-stack;
  padding-right: 15px;
  width: 98%;

  @include lg-up {
    padding-right: 0px;
  }
}

.saved-items__empty {
  font-family: $sans-serif-stack;
}

.item-group__title {
  color: $night-grey;
  font-size: 20px;
  border-bottom: none;
}

.saved-item__row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @include lg-up {
    flex-wrap: nowrap;
  }
}

.saved-item__buttons {
  display: flex;
  flex-shrink: 0;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  @include lg-up {
    width: unset;
  }
}

.saved-item__item-description {
  min-width: 0;
}

@mixin saved-item-text {
  font-size: 14px;
  & a {
    color: $night-grey;
    &:hover,
    &:focus {
      color: $crusta-orange;
    }
  }
}

.saved-item__title {
  @include saved-item-text;
  font-size: 20px;
  & a {
    color: $regal-blue;
  }
}

.saved-item__date {
  @include saved-item-text;
}

.saved-item__description {
  @include saved-item-text;
}

.saved-item__found-in {
  @include saved-item-text;
}

.saved-item__last-requested {
  @include saved-item-text;
  font-style: italic;
}
