@import "../styles";

.records__content {
  font-family: $sans-serif-stack;
  width: 100%;
  float: left;
  @include lg-up {
    width: calc(63% - #{$minimap-width});
  }
  &.hidden {
    @include show-on-lg-up;
  }
}

.loading {
  background: $night-grey;
  opacity: 0.8;
  top: 60px;
  bottom: 58px;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  @include md-up {
    top: 70px;
  }
  @include lg-up {
    top: 60px;
    bottom: 0;
    left: calc(37% + #{$minimap-width});
  }
}

.records-loading__text {
  font-size: 75px;
  color: $white;
}

.content__title {
  font-family: $sans-serif-stack;
  font-size: 24px;
  color: $regal-blue;
  border-bottom: 1px dotted $regal-blue;
}

.collection__title {
  font-size: 21px;
  color: $night-grey;
}

.collection__text {
  font-size: 16px;
}

.child__list {
  box-sizing: content-box;
  list-style: none;
  transition: $transition-default;
  padding-left: 20px;
  position: relative;
  &::before {
    content: "";
    background: $silver-grey;
    width: 3px;
    height: 100%;
    position: absolute;
    left: 8px;
    top: -12px;
  }
}

.child__list--top-level {
  @extend .child__list;
  padding-left: 0;
  &::before {
    content: none
  }
}

.child__list--bottom-level {
  background-color: $white;
  padding-left: 0px;
  &::before {
    content: none;
  }
}

.child__list-accordion {
  position: relative;
  border-radius: 3px;
}

.child__list-accordion--bottom-level {
  border: 3px solid $silver-grey;
  margin-bottom: 15px;
  &:hover {
    border-color: $crusta-orange;
  }
  &:focus-within {
    border-color: $regal-blue;
  }
}

// trims left border to the correct length
.child__list-accordion:last-of-type {
  &::before {
    content: "";
    width: 3px;
    background-color: #FFFFFF;
    height: calc(100% - 29px);
    position: absolute;
    top: 29px;
    left: -12px;
  }
}

.child__list-item {
  position: relative;
  padding: 20px;
  &:focus-within, &.active {
    border-color: $regal-blue;
  }
}

.child__list-item--object {
  border-bottom: 1px solid $wan-white-grey;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &:first-child {
    border-top: 1px solid $wan-white-grey;
  }
  &:focus-within, &:hover, .active {
    // background: $blue-gray;
    background: desaturate(darken($solitude-blue, 7%),10%);
  }
}

.child__list-item--collection {
  margin-bottom: 12px;
  border: 3px solid $silver-grey;
  border-radius: 3px;
  box-shadow: 0 0 5px $wan-white-grey;
  & .material-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    pointer-events: none;
  }
  &::before {
    content: "";
    width: 9px;
    height: 3px;
    border-bottom: 3px solid $silver-grey;
    position: absolute;
    top: 20px;
    left: -12px;
  }
  &:hover {
    border-color: $crusta-orange;
    & .material-icon {
      color: $crusta-orange;
    }
  }
  &:hover.active {
    border-color: inherit;
    &::after {
      color: inherit;
    }
  }
}

.child__list-item--collection.active {
  &::after {
    .material-icon {
      content: "expand_less";
      position: absolute;
      top: 15px;
      right: 15px;
      pointer-events: none;
    }
  }
}

.child__list-item--bottom-level {
  border: 0;
  margin-bottom: 0;
}

.child__list--bottom-level > .child__list-accordion {
  margin-top: 9px;
  margin-left: 9px;
  margin-right: 9px;
}

// Adds top border for objects following collections nested within objects
.child__list-accordion + .child__list-item {
  border-top: 1px solid #E3E1DD;
}

// removes before pseudo element from collections within object lists
.child__list--bottom-level .child__list-item--collection {
  &::before {
    content: none;
  }
}

// removes before pseudo element from top-level children
.child__list--top-level > div > .child__list-item--collection {
  &::before {
    content: none;
  }
}

.child__description {
  min-width: 0;
  order: 1;
  @include md-up {
    order: unset;
    flex-basis: 50%;
    flex-grow: 2;
  }
}

.child__buttons {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  order: 3;
  @include md-up {
    width: unset;
    order: unset;
    justify-content: flex-end;
    margin-bottom: 9px;
  }
}

.child__title {
  appearance: none;
  border: none;
  background: none;
  color: $black;
  text-align: left;
  font-weight: $font-weight-bold;
  cursor: pointer;
  padding-left: 0;
  padding-right: 20px;
  &:focus {
    outline: none;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.child__title--object {
  font-size: 16px;
}

.child__title--collection {
  font-size: 18px;
}

.child__text {
  color: $night-grey;
  font-size: 14px;
  font-weight: $font-weight-normal;
  order: 2;
  @include md-up {
    order: unset
  }
}
