@import "../styles";
@import "./saved-item";

.modal-saved-items__item-group {
  border-top: 1px dotted $regal-blue;
  &:first-of-type {
    margin-top: 22px;
  }
}

.modal-item-group__items {
  font-weight: $font-weight-bold;
  color: $night-grey;
}

.modal-item-group__items label {
  font-size: 15px;
}

.modal-item-group__title {
  @extend .item-group__title;
  font-size: 15px;
}

.modal-saved-item {
   line-height: 16px;
}

.modal-saved-item__title {
  @extend .saved-item__title;
  font-size: 14px;
}
