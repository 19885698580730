@import "../styles";
@import "inputs";

.search-form--home {
  justify-content: center;
  max-width: 1300px;
  margin: 30px 30px 110px 30px;
  @include md-up {
    margin: 59px 75px 190px 75px;
  }
  @include lg-up {
    margin: 64px 0 233px 0;
  }
}

.search-bar {
  width: 100%;
  background-color: $solitude-blue;
  padding-bottom: 24px;
}

.results, .search-form--results {
  margin: 0px 30px;
  @include lg-up {
    margin: 0px 80px;
  }
}

.search-form--results {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.input-group__search {
  justify-content: center;
}

.input-group__search-results {
  justify-content: flex-start;
  margin-top: 24px;
  @include md-up {
    margin-right: 30px;
    width: 460px;
  }
}

.input-group__search-controls {
  width: 100%;
  @include lg-up {
    width: calc(100% - 500px);
  }
}

.input-group__search-controls-results {
  flex-wrap: nowrap;
  margin-top: 24px;
  width: 100%;
  @include lg-up {
    width: unset;
  }
}

.input__search {
  @include lg-up {
    width: calc(100% - 650px);
  }
}

.input__search-results {
  width: unset;
}

.input__search-results input {
  height: 46px;
}

.select__search__wrapper {
  display: none;
  margin-right: 14px;
  @include md-up {
    display: block;
  }
}

.select__search__control {
  font-size: 16px;
  border: 1px solid $silver-grey;
  padding: 13px 15px;
  width: 180px;
  & > .material-icon {
    right: 15px;
    top: 13px;
  }
}

.select__search__option {
  font-size: 16px;
  padding: 16px 18px;
}

/**
* Styles for the search button on the home page
**/
.search__submit-btn {
  @include md-up {
    justify-content: center;
    min-width: 150px;
  }
}

/**
* Styles for the search button on the search results page
* 1. Removes the material-icon--space-before styles
**/
.search__results-submit-btn {
  height: 46px;
  padding: 10px 12px;
  min-width: unset;

  .material-icon {
    font-size: 24px;
    margin-left: 0px; /* 1 */
  }
}
