@import "../styles";

.mylist main {
  grid-column: span 12;
  margin-right: 15px;
  @include lg-up {
    grid-column: 1 / span 8;
    margin-right: 0px;
  }
}

.mylist__export-actions {
  @include show-on-lg-up;
}

.mylist__export-actions .btn {
  margin-right: 10px;
}

.mylist__sidebar {
  align-content: start;
  background: $desert-grey;
  gap: 12px;
  grid-column: 9 / span 4;
  justify-items: start;
  @include show-on-lg-up;
}
