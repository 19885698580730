// Colors by category

// Oranges
$flame-orange: hsl(17deg 76% 48%);
$crusta-orange: hsl(13deg 86% 60%);
$sandy-orange: hsl(25deg 87% 64%);
$peach-orange: hsl(30deg 100% 80%);
$rose-violet: hsl(0deg 100% 95%);

// Blues
$regal-blue: hsl(214deg 49% 19%);
$midnight-blue: hsl(214deg 72% 24%);
$yale-blue: hsl(214deg 70% 35%);
$water-blue: hsl(210deg 27% 82%);
$solitude-blue: hsl(213deg 29% 94%);

// Browns
$maroon-brown: hsl(23deg 100% 20%);
$rust-red: hsl(19deg 83% 34%);
$umber-brown: hsl(17deg 87% 38%);

// Greys
$silver-grey: hsl(45deg 6% 72%);
$wan-white-grey: hsl(40deg 10% 88%);
$desert-grey: hsl(60deg 10% 96%);

// Monochrome
$black: hsl(0deg 0% 0%);
$night-grey: hsl(0deg 0% 18%);
$mortar-grey: hsl(0deg 0% 36%);
$dark-grey: hsl(0deg 0% 66%);
$white: hsl(0deg 0% 100%);

$break-md: 580px;
$break-lg: 1024px;

$gutters-default: 15px;

$minimap-width: 100px;

// Typography
$sans-serif-stack: 'Lato', sans-serif;
$serif-stack: 'Manuale', serif;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-heavy: 900;

