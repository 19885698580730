@import "../base/utilities";

.captcha {
  max-width: 250px;
  & div > div > div {
    position: relative;
    transform-origin: left center;
    transform: scale(0.90);
    @media screen and (min-width: 1240px) {
      transform: scale(1);
    }
  }
}

