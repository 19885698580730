@import "../styles";
@import "./buttons";

.toggle-wrapper {
  width: 100%;
  z-index: 999;
  position: fixed;
  bottom: 0;
  left: 0;
  @include lg-up {
    display: none;
  }
}

.toggle-context {
  border-radius: 0 !important;
  width: 100%;
  justify-content: center;
  position: fixed;
  bottom: 0;
}

.toggle-context--active {
  opacity: 0;
  @include md-up {
    opacity: 1;
    background: $desert-grey;
    color: $night-grey;
    &:hover, &:focus {
      background: $wan-white-grey;
      color: $night-grey;
    }
  }
}
