@import "../styles";

.accordion {
  font-family: $sans-serif-stack;
}

.accordion__button {
  border-bottom: 1px dotted $regal-blue;
  font-family: inherit;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  letter-spacing: 2px;
  color: $regal-blue;
  cursor: pointer;
  &:focus {
    outline: none;
    border-bottom: 1px solid $regal-blue;
    font-weight: $font-weight-heavy;
  }
  &::after {
    font-family: Material Icons;
    content: "keyboard_arrow_down";
    float: right;
    font-size: 24px;
    line-height: 12px;
    text-transform: none;
    // }
  }
  &[aria-expanded=true] {
    &::after {
      content: "keyboard_arrow_up";
    }
  }
}

.panel__heading {
  font-family: inherit;
  font-size: 12px;
  font-weight: $font-weight-extra-bold;
  color: $night-grey;
  letter-spacing: 0.7px;
  text-transform: uppercase;
}

.panel__text {
  font-family: inherit;
  font-size: 15px;
  color: $night-grey;
  line-height: inherit;
  white-space: pre-line;
}

.panel__text a {
  color: $night-grey;
}

.panel__text--narrative {
  @extend .panel__text;
  line-height: 25px;
}

.panel__list--unstyled {
  list-style: none;
  font-family: inherit;
}
