@import "../variables/vars";

$transition-default: all 0.2s ease;

@mixin visually-hidden {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
    &:focus {
      clip: auto;
      height: auto;
      overflow: auto;
      position: absolute;
      width: auto;
      z-index: 1000;
  }
}

.bring-forward {
  z-index: 999999;
}

.flex {
  display: flex;
}


@mixin md-up {
  @media screen and (min-width: $break-md) { @content; }
}
@mixin lg-up {
  @media screen and (min-width: $break-lg) { @content; }
}

@mixin show-on-md-up {
  display: none;
  @include md-up {
    display: inherit;
  }
}

@mixin hide-on-md-up {
  display: inherit;
  @include md-up {
    display: none
  }
}

@mixin show-on-lg-up {
  display: none;
  @include lg-up {
    display: inherit;
  }
}

.hide-on-lg-up {
  display: inherit;
  @include lg-up {
    display: none;
  }
}

.loading-dots:after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite;}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: inherit;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 currentcolor,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 currentcolor,
      .5em 0 0 currentcolor;}}

.text--truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
