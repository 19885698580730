@import "../styles";

.viewer {
  position: absolute;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  background: $black;
  z-index: 998;
}

.mosaic-tile {
  margin: 0px !important;
}

.mosaic-root {
    top: 0px !important;
    left: 0px  !important;
    right: 0px !important;
    bottom: 0px !important;
}

.mirador-window-top-bar {
  border-top: 0 !important;
}

.MuiToolbar-root {
  align-items: flex-start !important;
}

.viewer-bar {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @include lg-up {
    justify-content: space-between;
  }
}

.viewer-bar__title {
  width: 100%;
  order: 1;
  margin-left: -37px;
  @include lg-up {
    width: unset;
    order: 0;
    margin-left: 0;
  }
  h2 {
    border: none;
    padding-bottom: 0;
  }
}

.viewer-bar__buttons {
  width:100%;
  order: 0;
  display: flex;
  justify-content: flex-end;
  @include lg-up {
    width: unset;
    order: 1;
  }

  & > .btn {
    margin: 0 10px 10px 0;
  }
}
