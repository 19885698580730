@import "../styles";
@import "inputs";

.results__header, .results__footer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @include md-up {
    align-items: flex-end;
  }
  @include lg-up {
    flex-wrap: nowrap;
    align-items: flex-end;
  }
}

.results__footer {
  @include lg-up {
    justify-content: flex-end;
  }
}

.results__summary {
  width: 100%;
  @include md-up {
    width: 50%;
    order: 0;
  }
  @include lg-up {
    width: unset;
    order: 1;
    text-align: right;
  }
}

.results__summary--text {
  font-family: inherit;
  min-width: 225px;
  @include lg-up {
    margin-bottom: 3px;
  }
}

.results__controls {
  width: 100%;
  @include md-up {
    order: 2
  }
  @include lg-up {
    width: unset;
    order: 0;
    flex-grow: 1;
  }
}

.results__pagination {
  width: 100%;
  @include md-up {
    width: 50%;
    order: 1;
    margin-bottom: 10px;
    text-align: right;
    align-self: flex-end;
  }
  @include lg-up {
    width: unset;
    order: 2;
    margin-left: 10px;
    margin-bottom: 0px;
  }
}

.select__sort__wrapper {
  position: relative;
  display: inline-block;
  margin-top: 16px;
}

.select__sort__control > .material-icon {
  top: 12px;
  right: 10px;
}

.select__sort__control {
  font-size: 14px;
  padding: 0px 15px;
  height: 45px;
  width: 200px;
  border: 1px solid $night-grey;
}

.select__sort__option {
  font-size: 14px;
  padding: 10px 15px;
}
