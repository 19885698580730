@import "../styles";

/**
* Styles for search facets
* 1. Makes "Apply Dates" button wrap on mobile
* 2. Positions legend inside fieldset instead of on top border line.
**/
.modal__body--search fieldset {
  flex-wrap: wrap; /* 1 */
  position: relative; /* 2 */
}

.facet {
  border-top: 1px solid $water-blue;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.facet:first-child {
  border: 0px;
  flex-direction: row;
}

.facet legend {
  position: absolute; /* 1 */
  top: 20px;
}

.facet__title {
  font-size: 12px;
}

.facet__items {
  overflow: hidden;
  transition: $transition-default;
  display: flex;
  flex-direction: column;
}

.facet input[type=number] {
  padding: 7px 12px;
  width: 80px;
  float: left;
  margin-right: 13px;
  border-radius: 3px;
  border: 1px solid $night-grey;
}

.facet__show-hide {
  font-family: $sans-serif-stack;
  font-size: 12px;
  color: $regal-blue;
  text-decoration: underline;
  appearance: none;
  border: none;
  background: none;
  letter-spacing: normal;
  text-transform: none;
}
