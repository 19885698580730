@import "../styles";

@mixin input-text {
  font-family: $sans-serif-stack;
  color: $night-grey;
}

@mixin placeholder-text {
  color: $mortar-grey;
  font-size: 18px;
}

input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  @include input-text;
  @include placeholder-text;
}

input::-ms-input-placeholder { /*Edge - only work on some properties */
  @include input-text;
  @include placeholder-text;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
}

.hide-label label {
  @include visually-hidden;
}

.input--outline {
  border: 1px solid;
}

.checkbox--online:not(:checked) + label,
.checkbox--online:checked + label {
  padding: 13px 15px 13px 45px;
  background: darken($desert-grey, 4%);
}

.checkbox--online:checked + label {
  background: $yale-blue;
  color: $white;
}

.checkbox--online:not(:checked) + label:before,
.checkbox--online:checked + label:before {
  top: 13px;
  left: 15px;
}

.checkbox--online:not(:checked) + label:after,
.checkbox--online:checked + label:after {
  background-color: $white;
  border: 1px solid $yale-blue;
  color: $yale-blue;
  top: 13px;
  left: 15px;
}

.dp__calendar {
  background-color: $white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 999;
  @include lg-up {
    right: 20px;
  }
}

.dp__top-bar {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.dp__month {
  font-weight: $font-weight-bold;
  text-align: center;
  width: 200px;
}

.dp__button {
  border: 1px solid $wan-white-grey;
  border-radius: 2px;
  color: $night-grey;
  cursor: pointer;
  line-height: 1;
  & .material-icon {
    color: $mortar-grey;
  }
}

.dp__table {
  border-collapse: collapse;
  width: 100%;

  th, td {
    font-size: 14px;
    text-align: center;
    user-select: none;
  }

  th {
    color: $mortar-grey;
    font-weight: $font-weight-normal;
    line-height: 1.6;
  }

  td {
    cursor: pointer;
    height: 36px;
    line-height: 1;
    width: 36px;
    &:hover {
      background-color: $flame-orange;
      color: $white;
    }

    &[aria-disabled="true"] {
      opacity: 0.5;
      pointer-events: none;
    }

    &[aria-selected="true"] {
      background-color: $flame-orange;
      color: $white;
    }

    &:not(:empty) {
      border: 1px solid $wan-white-grey;
    }
  }
}

/**
* Overrides react-datepicker.css to fit the full time into the visible space
**/
.react-datepicker__time-list-item {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.select__wrapper {
  position: relative;
}

.select__control {
  @include input-text;
  cursor: pointer;
  background-color: $white;
  border-radius: 0px;
  text-align: left;
  & > .material-icon {
    position: absolute;
  }
  &:focus {
    outline: none;
    font-weight: $font-weight-bold;
  }
}

.select__menu {
  position: absolute;
  background-color: $white;
  border-radius: 0;
  list-style: none;
  padding-left: 0;
  top: 0px;
  width: 100%;
  z-index: 999;
  &.open {
    border: 1px solid $night-grey;
  }
}

.select__option {
  color: $black;
  font-family: $sans-serif-stack;
  cursor: pointer;
  &.is-focused {
    background: darken($desert-grey, 4%);
  }
  &.is-selected {
    font-weight: $font-weight-bold;
    &::after {
      font-family: Material Icons;
      font-size: 20px;
      content: "check";
      position: absolute;
      right: 18px;
      color: $flame-orange;
    }
  }
}
