@import "../styles";

.not-found {
  height: 100%;
  max-width: 80%;
  font-family: $serif-stack;
  text-align: center;
  min-height: 60vh;
}

.not-found__icon > .material-icon {
  font-size: 100px;
  color: $night-grey;
}

.not-found__text {
  font-family: $sans-serif-stack;
  font-size: 20px;
}
