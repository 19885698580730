@import "../styles";

.agent__attributes {
  width: 100%;
  display: flex;
  column-gap: 15px;
}

.agent-attribute {
  margin-bottom: 24px;
  min-width: 18%;
  flex-grow: 1;
  @include lg-up {
    margin-bottom: 27px;
  }
}

.agent-attribute__value, .agent-note__value {
  white-space: pre-line;
}
