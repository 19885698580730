@import "../variables/vars";

.btn--minimap {
  background: $desert-grey;
  position: fixed;
  top: 80px;
  right: 30px;
  white-space: wrap;
  z-index: 999;
  @mixin md-up {
    top: 90px;
  }
}

.btn--minimap__text {
  width: 60px;
  text-align: left;
}

.btn--minimap__icon {
  height: 30px;
  width: 30px;
}
