@import "../styles";

.backend-error {
  height: 100%;
  max-width: 80%;
  font-family: $serif-stack;
  text-align: center;
  min-height: 60vh;
  padding-bottom: 100px;
}

.backend-error__icon > .material-icon {
  font-size: 100px;
  color: $night-grey;
}

.backend-error__text {
  font-family: $sans-serif-stack;
  font-size: 20px;
}

.backend-error__header {
  font-family: $sans-serif-stack;
  font-size: 24px;
  font-weight: bold;
  text-align: left;
}

.backend-error__message {
  font-family: monospace;
  font-size: 16px;
  overflow-wrap: break-word;
  text-align: left;
}
