.modal-loading__text {
  font-size: 28px;
}

.modal-list {
  width: 100%;
  float: left;
  @include lg-up {
    width: 66%
  }
}

.modal-form {
  float: left;
  width: 100%;
  min-height: 100%;
  background-color: $desert-grey;
  border-top: 1px solid $wan-white-grey;
  font-size: 14px;
  @include lg-up {
    width: 34%;
    float: right;
  }
}

.form-group {
  float: left;
  margin: 10px 0;
  width: 100%;
  &:first-of-type {
    margin-top: 0;
  }
}

.modal-form input, .modal-form textarea, .modal-form .select__modal__control {
  width: 100%;
  border: 1px solid $night-grey;
  padding: 10px;
  &[type=checkbox] {
    width: unset;
    float: left;
  }
}

.select__modal__control .material-icon {
  font-size: 20px;
  top: 25px;
  right: 10px;
}

.select__modal__menu {
  font-size: 14px;
  top: 16px !important;
}

.modal-form label {
  font-weight: $font-weight-bold;
  margin-bottom: 5px;
}

.select__modal__option {
  padding: 5px 10px;
}

// font-weight for checkboxes
.modal-form input[type=checkbox] + label {
  font-weight: $font-weight-normal;
}

.modal-form .is-invalid {
    border: 1px solid $flame-orange;
}

.modal-form__buttons {
  float: left;
  width: 100%;
  & > .btn {
    margin: 10px 10px 0 0;
  }
}

.selected-totals {
  width: 50%;
  float: right;
  text-align: right;
  font-weight: $font-weight-bold;
}
