@import "../styles";

.slide--right {
  transform: translateX(-400px);
}

.slide--right--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.slide--right--before-close {
  opacity: 0;
  transform: translateX(-400px);
}

.slide--left {
  transform: translateX(400px);
}

.slide--left--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.slide--left--before-close {
  opacity: 0;
  transform: translateX(400px);
}

.modal__body {
  @include lg-up {
    display: flex;
  }
}
