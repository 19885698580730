html {
  scroll-behavior: smooth;
}

/**
* Ensure that the footer is always on the bottom of the page. The RAC Style Library
* applies these same styles to the <body> element, but they are only effective when the
* footer element is a direct child of the flex container, which in this site is the
* #root div.
*/
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

form {
  width: 100%;
}

.header {
  position: sticky;
  top: 0;
  z-index: 999;
}