@import "../styles";

.btn--dark-gray {
  background: $dark-grey;
  color: $white;
  border: 1px solid $dark-grey;
  &:hover {
    background: darken($dark-grey, 5%);
    color: $white;
  }
}

.btn--new-search {
  position: absolute;
}

.search__submit-btn {
  height: 62px;
  @include md-up {
    .material-icon {
      font-size: 24px;
    }
  }
}

.btn--filter {
  padding: 12px 16px;
  font-size: 14px;
  text-transform: none;
  letter-spacing: unset;
  & .material-icon {
    font-size: 18px;
  }
}

@mixin records-btn {
  z-index: 998;
  &.saved {
    @extend .btn--dark-gray;
  }
}

@mixin btn--content {
  @include records-btn;
  @include md-up {
    position: relative;
  }
}

.btn-add--content {
  @include btn--content;
}

.btn-launch--content {
  @include btn--content;
}

@mixin records-detail-btn {
  @include records-btn;
  width: 100%;
  justify-content: center;
  @include md-up {
    width: unset;
  }
}

.btn--detail {
  @include records-detail-btn;
  justify-content: center;
  @include md-up {
    width: unset;
  }
}

.btn--minimap-info {
  float: right;
  border: none;
}

.btn--agent-identifier {
  font-weight: $font-weight-bold;
  width: 100%;
}
