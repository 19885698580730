// @import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900|Manuale:300,400,700|Material+Icons&display=swap');
@import '../variables/vars';

p,
a {
  line-height: inherit;
  font-size: inherit;
}

@mixin page-title {
  font-family: $sans-serif-stack;
  font-size: 24px;
  color: $regal-blue;
  @include lg-up {
    font-size: 28px;
  }
}

.agent__title {
  @include page-title;
}

.agent__section-title {
  text-transform: uppercase;
  font-family: $sans-serif-stack;
  font-size: 14px;
  color: $regal-blue;
  letter-spacing: 2px;
}

.agent-attribute__label, .agent-note__label {
  font-family: $sans-serif-stack;
  font-size: 12px;
  font-weight: $font-weight-bold;
  letter-spacing: 0.65px;
  text-transform: uppercase;
  color: $night-grey;
}

.agent-attribute__value, .agent-note__value, .agent-note__source {
  font-family: $sans-serif-stack;
  font-size: 16px;
  color: $night-grey;
  line-height: 25px;
}

.mylist__title {
  @include page-title;
  float: left;
  @include lg-up {
    float: none;
  }
}

.results__title {
  @include page-title;
}

.results__summary {
  font-family: $sans-serif-stack;
  font-size: 16px;
}

