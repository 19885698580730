.modal--facet {
  bottom: auto;
  top: 0;
  left: 0;
  right: unset;
  max-width: 420px;
  height: 100vh;
  border-radius: 0px;
  background-color: $solitude-blue;
  @include md-up {
    width: 420px;
  }
}

.modal__header--search {
  color: $regal-blue;
  background-color: $solitude-blue;
  height: auto;
}
