@import "../styles";

.agent__wrapper {
  margin: 0px 30px;
  @include lg-up {
    margin: 0 40px 0 80px;
  }
}

.agent__wrapper--description {
  box-sizing: content-box;
  display: flex;
  flex-wrap: wrap;
  @include lg-up {
    flex-wrap: nowrap;
  }
}

.agent__main {
  width: 100%;
  @include lg-up {
    padding-right: 48px;
  }
}

.agent__note {
  width: 100%;
  float: left;
}

.agent__sidebar {
  width: 100%;
  @include lg-up {
    width: 33%;
    padding: 0 40px;
  }
}
