
$confirm-modal-content-margin: 10%;
$confirm-modal-content-margin-lg: 30%;

.modal--confirm {
  top: $confirm-modal-content-margin;
  left: $confirm-modal-content-margin;
  right: $confirm-modal-content-margin;
  bottom: auto;
  @include lg-up {
    top: $confirm-modal-content-margin-lg;
    left: $confirm-modal-content-margin-lg;
    right: $confirm-modal-content-margin-lg;
  }
}

.modal-message {
  font-size: 18px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.modal-buttons--confirm {
  width: 100%;
  text-align: center;
  & > .btn {
    margin-right: 10px;
  }
}
