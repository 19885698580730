@import "../styles";
@import "./buttons";

.mylist__actions {
  display: block;
  float: right;
}

.mylist__actions--dropdown {
  position: absolute;
  right: 0;
}
