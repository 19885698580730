@keyframes spin-counterclockwise {
    from {
        transform:rotate(360deg);
    }
    to {
        transform:rotate(0deg);
    }
}

@keyframes spin-clockwise {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
