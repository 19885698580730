@import "../styles";
@import "./buttons";

.records__detail {
  background-color: $desert-grey;
  width: 100%;
  height: 100%;
  padding: 0px 30px 110px 30px;
  float: left;
  font-family: $sans-serif-stack;
  &.hidden {
    @include show-on-lg-up;
  }
  @include md-up {
    padding: 0px 40px 50px 40px;
  }
  @include lg-up {
    width: 37%;
    max-height: 100vh;
    position: sticky;
    top: 60px;
    overflow: scroll;
  }
}

.records__nav {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  @include md-up  {
    margin-top: 30px;
  }
  a {
    margin-bottom: 0;
  }
}

.records__title {
  font-family: $sans-serif-stack;
  font-size: 28px;
  color: $regal-blue;
}

.panel__section--flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.found-in {
  box-sizing: content-box;
  font-family: $sans-serif-stack;
  font-size: 13px;
  & svg {
    width: 13px;
    vertical-align: middle;
    padding-right: 2px;
    fill: $regal-blue;
  }
}

.found-in__subcollection {
  & .material-icon {
    font-size: inherit;
    line-height: inherit;
    padding-right: 2px;
  }
}

.found-in__link {
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  font-weight: $font-weight-normal;
}
