@import "../styles";

.hero {
  margin-top: 10px;
  @include md-up {
    margin-top: 113px;
  }
  @include lg-up {
    margin-top: 103px;
  }
}

